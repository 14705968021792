var __jsx = React.createElement;
import { useCustomTheme } from '@utils/theme';
import React, { useEffect, useState } from 'react';
import { Button } from '../components/Button';
import { Features } from '../components/Features/Features';
import { Hero } from '../components/Features/Hero';
import { Pricing } from '../components/Features/Pricing';
import { Head } from '../components/Head';
import { Layout } from '../components/v1/LayoutV1';

var FeaturesPage = function FeaturesPage(props) {
  var _useCustomTheme = useCustomTheme(),
      isDark = _useCustomTheme.isDark,
      toggleTheme = _useCustomTheme.toggleTheme;

  var _useState = useState(isDark),
      isDefaultDark = _useState[0];

  useEffect(function () {
    if (!isDark) {
      toggleTheme();
    }
  }, [isDark, toggleTheme]);
  useEffect(function () {
    return function () {
      if (!isDefaultDark) {
        toggleTheme();
      }
    };
  }, [isDefaultDark, toggleTheme]);
  return __jsx(React.Fragment, null, __jsx(Head, {
    title: "Exodia - Pro NFT Trading Tools",
    description: "Get an edge over the rest by utilizing NFT trading tools, data and alpha",
    pageName: "Features",
    pageProps: props
  }), __jsx(Layout, null, __jsx("div", {
    className: ""
  }, __jsx(Hero, null)), __jsx("div", {
    className: "mt-20"
  }, __jsx(Features, null)), __jsx("div", {
    id: "Pricing",
    className: "mt-8"
  }, __jsx(Pricing, null)), __jsx("div", {
    className: "mt-20 flex items-center justify-center"
  }, __jsx(Button, {
    color: 'gradient-v2',
    rounded: true,
    href: '/dashboard',
    asLink: true
  }, "Open App"))));
};

export default FeaturesPage;