import React from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { Button } from '@components/Button';
import dashboardImage from './images/dashboard.png';
import firehoseImage from './images/fire.png';
import traitsImage from './images/traits.png';
import listingsImage from './images/alpha.png';
import alertsImage from './images/alerts.png';
import mintImage from './images/mint.png';
import walletImage from './images/wallet.png';
import { BASE_URL } from '@utils/config';

interface Feature {
  title: string;
  body: string;
  buttonTitle?: string;
  href?: string;
  image: React.ReactNode;
}

export const Features: React.FC<{}> = props => {
  const features: Array<Feature> = [
    {
      title: 'Design your own trading experience',
      body: `Customize a pro NFT dashboard and focus on what works for you.`,
      buttonTitle: 'View Dashboard',
      href: `${BASE_URL}/dashboard`,
      image: <Image src={dashboardImage} alt="" />,
    },
    {
      title: 'Find opportunities before others',
      body: 'See where attention is being focused and which floors are being swept. Use our deal sniper to quickly purchase valuable NFTs as they’re listed.',
      image: <Image src={firehoseImage} alt="" />,
    },
    {
      title: 'Fine-tuned, configurable alerts',
      body: `Set alerts for specific traits and prices. Follow collections, traits, and wallets and now when your favorite investors buy, sell or list any NFT.`,
      image: <Image src={alertsImage} alt="" />,
    },
    {
      title: 'Mint analytics',
      body: 'See which collections are minting in real time. Instantly follow mint data and decide whether to join in on the action.',
      image: <Image src={mintImage} alt="" />,
    },
    {
      title: 'Trait based price charts',
      body: `Refine your rarity strategy by charting differences in price for various collection traits. Visualize liquidity for different traits by spotting trait-specific trading volume.`,
      image: <Image src={traitsImage} alt="" />,
    },
    {
      title: 'Multi wallet porftolio tracker',
      body: `Follow the holdings and profit levels of multiple wallets with ease. Know when certain wallets are minting, buying, selling, or receiving any NFT.`,
      image: <Image src={walletImage} alt="" />,
    },
    {
      title: 'Exclusive alpha group',
      body: `Join our community of pro-traders in our alpha channel. We have one of the strongest and most exclusive alpha groups in the space, run by our dedicated analysts making daily NFT calls.`,
      image: <Image src={listingsImage} alt="" />,
    },
    // {
    //   title: 'Find Opportunities Now',
    //   description: [
    //     { body: 'Set up alerts based on collections and smart wallets' },
    //     { body: 'Follow real time sales, lists and mints' },
    //     { body: 'Analyize multiple wallets simultaneously' },
    //   ],
    //   image: <Image src={firehoseImage} alt="" />,
    // },
    // {
    //   title: 'Discover Hidden Gems',
    //   description: [
    //     {
    //       body: `Valuate NFT collections using our unique rarity analysis tools, including trait rarity and score.`,
    //     },
    //     {
    //       body: `Analyize trait floor prices for any collection.`,
    //     },
    //   ],
    //   image: <Image src={rarityImage} alt="" />,
    // },
  ];

  return (
    <div className="space-y-16 md:space-y-20">
      {features.map((feature, index) => (
        <FeatureImage
          key={feature.title}
          imageSide={(index / 2) % 1 === 0 ? 'left' : 'right'}
          {...feature}
        />
      ))}
    </div>
  );
};

const FeatureImage = (feature: Feature & { imageSide: 'right' | 'left' }) => {
  return (
    <div key={feature.title} className="grid lg:grid-cols-2 gap-16">
      <div
        className={classNames('max-w-md flex flex-col justify-center mx-auto', {
          'lg:order-2': feature.imageSide === 'left',
        })}
      >
        <p className="font-grifter text-2xl md:text-4xl">{feature.title}</p>
        <div className="mt-4">
          <div className="text-lg md:text-xl">{feature.body}</div>
          {feature.buttonTitle ? (
            <div className="mt-10">
              <Button color={'gradient-v2'} rounded href={feature.href} asLink>
                <span>{feature.buttonTitle}</span>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classNames({ 'lg:order-1': feature.imageSide === 'left' })}>
        <div className="text-center shadow-xl">{feature.image}</div>
      </div>
    </div>
  );
};
