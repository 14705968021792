import React from 'react';

import classNames from 'classnames';

import CheckMark from '../../assets/icons/check-mark.svg';
import { useWeb3Context } from '../../utils/Web3Context';
import { Button, ButtonColor } from '../Button';
import { ETH_SYMBOL } from '../../utils/price';
import { ONE_MONTH_SUBSCRIPTION_PRICE, SIX_MONTHS_SUBSCRIPTION_PRICE } from '../../utils/config';
import { SubHeading } from '@components/Typography';
import { isMobileDevice } from '@utils/general';

export const Pricing: React.FC<{}> = props => {
  const features = React.useMemo(() => {
    return [
      'Exclusive alpha community access',
      'Fast NFT purchases',
      'Improved data speed',
      'Dashboard customization',
      'Mutiple wallet analysis',
    ];
  }, []);

  return (
    <div className="bg-gray-100 dark:bg-gray-950 border border-gray-200 dark:border-gray-750 rounded-xl pt-10 pb-8 px-8 text-center flex flex-col h-full">
      <div
        className="px-8 sm:px-12 md:px-24 lg:px-28 max-w-screen-xl mx-auto pb-12 flex justify-center"
        id="pricing"
      >
        <ul className="mt-6 grid gap-4">
          <SubHeading> Get premium to unlock:</SubHeading>
          {features.map(feature => {
            return (
              <li key={feature} className="flex text-gray-850 dark:text-gray-300">
                <div className={'text-neon-green-500'}>
                  <CheckMark className="w-6 h-6" />
                </div>
                <div className="ml-3 md:text-left">{feature}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="grid lg:grid-cols-3 gap-4">
        <PricingTier
          label="GET 1 MONTH"
          color="white"
          price={ONE_MONTH_SUBSCRIPTION_PRICE}
          term={1}
          live
        />
        <PricingTier
          label="GET 6 MONTHS"
          color="gradient-v2"
          price={SIX_MONTHS_SUBSCRIPTION_PRICE}
          term={6}
          live
        />
        <PricingTier label="GET LIFETIME" color="black" soldOut live />
      </div>
    </div>
  );
};

interface PricingTierProps {
  title?: string;
  label: string;
  live: boolean;
  color: ButtonColor;
  price?: number;
  term?: number; // in months
  subtitle?: string;
  description?: string;
  soldOut?: boolean;
}

export const PricingTier: React.FC<PricingTierProps> = props => {
  const { onPurchase } = useWeb3Context();

  return (
    <div className="relative">
      <div
        className={classNames({
          'absolute inset-0 bg-gray-400 dark:bg-gray-950 opacity-50 rounded-xl': !props.live,
        })}
      ></div>
      <div className="">
        <div className={'font-grifter text-2xl'}>
          {props.soldOut ? 'Sold out' : `${props.price}${ETH_SYMBOL}`}
        </div>
        <div className="max-w-xs mx-auto w-full mt-4">
          <Button
            color={props.color}
            block
            rounded
            size={isMobileDevice() ? 'sm' : 'lg'}
            disabled={!props.live}
            asLink
            href={props.soldOut ? 'https://opensea.io/collection/exodia-analytics' : ''}
            target={props.soldOut ? '_blank' : undefined}
            rel={props.soldOut ? 'noreferrer' : undefined}
            onClick={async () => {
              if (!props.soldOut) await onPurchase(Number(props.price));
            }}
          >
            {props.label}
          </Button>
        </div>
      </div>
    </div>
  );
};
